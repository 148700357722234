import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Instructions.css';

const getUploadSpeed = () => {
  if (navigator.connection && navigator.connection.downlink) {
    const downlinkSpeed = navigator.connection.downlink; // in Mbps
    const uploadSpeed = downlinkSpeed ; // Convert Mbps to MB/s

    return uploadSpeed.toFixed(2)
  } else {
    return 1
  }
};

const Instructions = () => {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get('userid');
  const id = searchParams.get('id');
  const name = searchParams.get('name')
  const height = searchParams.get('height')
  const weight = searchParams.get('weight')
  const age = searchParams.get('age')
  const gender = searchParams.get('gender')

 
  useEffect(() => {
   // const speed = getUploadSpeed()
    
    // if(speed < 40){
    //   navigate(`/speed-error?speed=${speed}&userid=${userId}`);
    // }
    
    if(id){
      navigate(`/exist-result?id=${id}`);
    }
  }, [])
  
  const handleProceed = () => {
    if (userId) {
      const speed = getUploadSpeed()
      if(speed < 0.5){
        navigate(`/speed-error?speed=${speed}&userid=${userId}`);
      }else{
        navigate(`/face-scan?userid=${userId}&name=${name}&height=${height}&weight=${weight}&age=${age}&gender=${gender}`);
      }
    } else {
      navigate('/form');
    }
  };

  return (
    <>
       <div className="welcome-container">
            <p className="title">Instructions</p>
            <div className="profile">
                <img src="assests/Mask group.svg"/>
            </div>
            <div className="instruction-container">
                <div className="instruction-title">Face Scan</div>
                <ol>
                <li>The ambient light should be bright and consistent and must be white.</li>
                <li>Remove your masks, eye- wear, or clothing obstruction the face for the duration of scan.</li>
                <li>Keep your face and phone steady to avoid movements during the scan.</li>
                <li>For light mode: Make sure your face is mostly covered by the blue circle.</li>
                <li>If prompted, allow the camera permission.</li>
                </ol>
            </div>
            <button className="proceed-button" onClick={handleProceed}>
                    Proceed
            </button>
            <div className="footer">
                <span style={{"textDecoration": "bold"}}>Disclaimer:</span> For Investigational Use Only. Our Health App is not a substitute for the clinical judgment of a healthcare professional . It is intended to improve your awareness of general wellness. It does not diagnose, treat , mitigate or prevent any disease, symptom, disorder or abnormal physical state. Consult with a health care professional or emergency services if you believe you may have a medical issue .
            </div>

        </div>

    </>
  )
}

export default Instructions