import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TryAgain.css';

const TryAgain = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get('userid');
  const name = searchParams.get('name')
  const height = searchParams.get('height')
  const weight = searchParams.get('weight')
  const age = searchParams.get('age')
  const gender = searchParams.get('gender')

  const handleTryAgain = () => {
    navigate(`/face-scan?userid=${userId}&name=${name}&height=${height}&weight=${weight}&age=${age}&gender=${gender}`); // Navigate to '/' with userId
  };

  return (
    <div className='try-again-container'>
       <h1  className='speed-msg'>Unstable Network</h1>
        <button className='try-again-button' onClick={handleTryAgain}>Try Again</button>
    </div>
  );
};

export default TryAgain;
